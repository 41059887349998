<template>
    <styled-interface hide-toolbar>
        <styled-card
            class="mt-5 mb-5"
            :width="800"
            hide-toggle
            centered>
            <template #heading>
                Install Google Tag Manager
            </template>
            <div class="pa-5">
                <p>
                    Use the scripts below to activate BuyerBridge's ability to
                    track calls, retarget traffic and report on user behavior.
                    You can copy the scripts directly below or
                    <a
                        href="#"
                        @click.prevent="onDownloadCode">
                        click here to download a zip file
                    </a>
                    that includes both scripts with instructions.
                </p>
                <h3>Primary Script</h3>
                <p>
                    Please paste the primary script below on every page as high in
                    the <strong>&#x3C;head&#x3E;</strong> of the page as possible.
                </p>
                <copy-text-area
                    :download-filename="`${containerId}-primary.txt`"
                    class="mb-4"
                    hide-email>
                    <pre>{{ mainScript }}</pre>
                </copy-text-area>
                <h3>Secondary Script</h3>
                <p>
                    Additionally, paste the secondary script below immediately after the opening
                    <strong>&#x3C;body&#x3E;</strong> tag on every page.
                </p>
                <copy-text-area
                    :download-filename="`${containerId}-secondary.txt`"
                    class="mb-4"
                    hide-email>
                    <pre>{{ secondaryScript }}</pre>
                </copy-text-area>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import StyledCard from '../globals/StyledCard';
import StyledInterface from '../globals/StyledInterface';
import CopyTextArea from '../globals/CopyTextArea';
import {
    downloadGtmCode,
    getMainScript,
    getSecondaryScript
} from '../../helpers/gtmCode';

export default {
    title: 'Google Tag Manager Setup',
    components: {
        CopyTextArea,
        StyledInterface,
        StyledCard
    },
    data() {
        return {
            containerId: null
        };
    },
    computed: {
        mainScript() {
            return getMainScript(this.containerId);
        },
        secondaryScript() {
            return getSecondaryScript(this.containerId);
        }
    },
    created() {
        this.containerId = this.$route.params.container_id;
    },
    methods: {
        onDownloadCode() {
            downloadGtmCode(this.containerId);
        }
    }
};
</script>
